export default function IconaGetRwd() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path
                d="M13.4007 9.07863L7.51546 14.5653C7.07282 14.9797 6.35918 14.9743 5.92476 14.553L2.05723 10.8027C1.62976 10.3882 1.62152 9.70541 2.03879 9.27886L9.12357 2.03663L8.86644 2.1448H13.7251C14.3203 2.1448 14.8016 2.62728 14.8016 3.22653V7.90015C14.8016 8.09882 14.9627 8.25986 15.1614 8.25986C15.36 8.25986 15.5211 8.09882 15.5211 7.90015V3.22653C15.5211 2.23034 14.718 1.42538 13.7251 1.42538H8.86644C8.76967 1.42538 8.67698 1.46437 8.60931 1.53354L1.52453 8.77578C0.828799 9.48697 0.842555 10.6269 1.55641 11.3192L5.42394 15.0695C6.13418 15.7582 7.28458 15.7669 8.00658 15.091L13.8913 9.60485C14.0366 9.46938 14.0446 9.24176 13.9091 9.09645C13.7736 8.95114 13.546 8.94316 13.4007 9.07863ZM11.7441 6.82102C12.6381 6.82102 13.3628 6.09631 13.3628 5.20233C13.3628 4.30835 12.6381 3.58364 11.7441 3.58364C10.8501 3.58364 10.1254 4.30835 10.1254 5.20233C10.1254 6.09631 10.8501 6.82102 11.7441 6.82102ZM11.7441 6.1016C11.2475 6.1016 10.8448 5.69899 10.8448 5.20233C10.8448 4.70567 11.2475 4.30306 11.7441 4.30306C12.2408 4.30306 12.6434 4.70567 12.6434 5.20233C12.6434 5.69899 12.2408 6.1016 11.7441 6.1016Z"
                fill="black"
                stroke="black"
                strokeWidth="0.0833333"
            ></path>
            <path d="M4.16667 9.16666L7.5 12.5" stroke="black" strokeWidth="0.833333" strokeLinecap="round"></path>
        </svg>
    );
}
